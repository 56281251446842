<template>
  <div class="full-container">
    <title-header :title="'平板锁设置ID记录'"/>

    <div class="page-container record-container">
      <ul class="flex-column fields-box">
        <!-- <li class="fields-list">
          <p class="fields-item-label">时间区间：</p>
          <div class="flex-vertical fields-item">
            <div class="time-input start-time-input" @click="controlDate('start')">{{ startTime || ' ' }}</div>
            <div class="split">至</div>
            <div class="time-input end-time-input" @click="controlDate('end')">{{ endTime || ' ' }}</div>
          </div>
        </li> -->

        <li class="fields-list">
          <p class="fields-item-label">请输入平板锁号或是IMEI：</p>
          <div class="flex-no-wrap fields-item">
            <input type="number" class="fields-input" v-model="inputNo">
          </div>
        </li>
        <!-- <li class="flex fields-list">
          <p class="fields-item-label">IMEI号：</p>
          <div class="flex-no-wrap fields-item">
            <input type="number" class="fields-input" v-model="imei">
          </div>
        </li> -->
      </ul>
      <md-button round type="primary" class="bg fields-btn-search" @click="search">查询</md-button>

      <table cellspacing="0" cellpadding="0" class="test-table">
        <tr class="tr-header">
          <th>锁号</th>
          <th>IMEI</th>
          <th>测试时间</th>
          <th>状态</th>
        </tr>
        <tr class="tr-body" v-for="(history, index) in historyList" :key="index">
          <td>{{history.lockNo}}</td>
          <td>{{history.imei}}</td>
          <td>{{history.createTime}}</td>
          <td>
            <md-icon name="right" size="md" v-if="history.statusCode === 200"></md-icon>
            <md-icon name="wait" size="md" v-if="history.statusCode !== 200"></md-icon>
          </td>
        </tr>
        <tr v-if="historyList.length === 0">
          <td style="text-align: center" colspan="4">暂无数据</td>
        </tr>
      </table>

      <div class="total">当前 {{currentPage}} 页，共 {{total}} 条数据</div>

      <div class="flex pager-container">
        <div class="pager-btn preview" @click="previewPage">上一页</div>
        <div class="pager-btn next" @click="nextPage">下一页</div>
      </div>


    </div>


    <div class="date-picker-box-modal" v-if="visible">
      <div class="modal-box">
        <div class="flex modal-action-box">
          <div class="modal-action-btn cancel-btn" @click="controlModal(false)">取消</div>
          <div class="modal-action-tips">请选择时间</div>
          <div class="modal-action-btn certain-btn" @click="containDate">确认</div>
        </div>
        <md-date-picker
            ref="datePicker"
            type="datetime"
            :default-date="currentDate"
            is-view
            keep-index
        ></md-date-picker>
      </div>

    </div>

    </div>


</template>

<script>

import TitleHeader from "../../components/TitleHeader";
import axios from "axios";
import {Toast} from "mand-mobile";
import Api from "../../assets/config/api.config";
export default {
  name: "record",
  components: {
    TitleHeader
  },
  data() {
    return {
      visible: false,
      currentDate: new Date(),
      selectDate: '',
      selectItem: '',
      startTime: '',
      endTime: '',
      inputNo: '', // 锁号查询
      currentPage: 1, // 当前页码数
      pageNumber: 0, // 页码总数
      historyList: [],
      total: 0,  // 总条数
      configList: []
    }
  },
  created() {
    // this.getConfigList()
    this.getRecordList();
  },
  methods: {
    // 获取查询记录
    getRecordList() {
      let params = {};
      if(this.inputNo.length === 10) {
        params = {
          lockNo: this.inputNo,
          curPage: this.currentPage
        }
      }else if(this.inputNo.length === 15) {
        params = {
          imei: this.inputNo,
          curPage: this.currentPage
        }
      }else if(this.inputNo.length > 0 && this.inputNo.length !== 10 && this.inputNo.length !== 15) {
        Toast.info('请输入正确的平板锁号和IMEI');
        return false;
      }
      
      axios.get(Api.getSettingHistory, {params: params}).then(res => {
        if(res.data.code) {
          this.historyList = res.data.data.records;
          this.total = res.data.data.total;
          this.pageNumber = Math.ceil(this.total / res.data.data.size);
        }

      }).catch(err => {
        if(err.response.status === 401) {
          Toast.info("请重新登录");
          this.$router.push({path: '/login'});
        }
      })
    },
    controlDate(item) {
      this.selectDate = '';
      this.selectItem = item;
      this.controlModal(true);
    },
    controlModal(bool) {
      this.visible = bool;
    },
    containDate() {
      this.selectDate = this.$refs.datePicker.getFormatDate();
      if(this.selectItem === 'start') {
        this.startTime = this.selectDate;
      }else {
        this.endTime = this.selectDate;
      }
      this.visible = false;
    },
    // 上一页
    previewPage() {
      if(this.currentPage > 1) {
        this.currentPage--;
        this.getRecordList()
      }else {
        Toast.info('已经是第一页了')
      }
    },
    nextPage() {
      if(this.currentPage < this.pageNumber) {
        this.currentPage++;
        this.getRecordList();
      }else {
        Toast.info('已经是最后一页了');
      }
    },
    search() {
      this.currentPage = 1;
      this.getRecordList();
    }
  },
}
</script>

<style lang="less" src="./record.less" scoped></style>
<style lang="less" scoped>
  .fields-btn-search {
    margin: 0.5rem 0 !important;
  }

</style>
